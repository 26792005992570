import { RouteRecordRaw } from 'vue-router';
export const pageRouterOne: Array<RouteRecordRaw> = [
  {
    path: '/DownloadOne',
    name: 'DownloadOne',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageListOne/pageOne/login.vue'),
  },
];
