import { storage } from './storage';
export const common = {
  GetQueryString(name: string) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
};
export const getChannelIdOrSetChannelId = (): string => {
  if (!storage.getItem('channelUId', 1)) {
    let text = 'bjt';
    const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 32; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    storage.setItem('channelUId', text, null, 1);
    return text;
  } else {
    return storage.getItem('channelUId', 1) || '';
  }
};

export const isIos = () => {
  const u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
};

export const isPhone = () => {
  const u = navigator.userAgent,
    app = navigator.appVersion;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isAndroid) {
    //这个是安卓操作系统
    return 'Android';
  }
  if (isIOS) {
    return 'IOS';
    //这个是ios操作系统
  }
  return 'PC';
};

export const isNull = (o: any) => {
  return o === null || o === '' || o === undefined;
};
export const isObj = (o: object) => {
  // 是否对象
  return Object.prototype.toString.call(o).slice(8, -1) === 'Object';
};
export const isObjNull = (o: object) => {
  return Object.keys(o).length === 0;
};
export const isArray = (o: string | []) => {
  // 是否数组
  return Object.prototype.toString.call(o) === '[object Array]';
};
export const isFunction = (o: any) => {
  // 是否函数
  return Object.prototype.toString.call(o).slice(8, -1) === 'Function';
};
export const isString = (o: string) => {
  // 是否字符串
  return Object.prototype.toString.call(o).slice(8, -1) === 'String';
};
export const isFile = (o: any) => {
  // 是否文件
  return Object.prototype.toString.call(o).slice(8, -1) === 'File';
};
export const isArrNull = (o: any) => {
  return JSON.stringify(o) === '[]';
};

/**
 * S 时间格式转换方法
 * */
export const baseDayTimeMethod = {
  formatDate(date: string) {
    const arr = date.split('T');
    const d = arr[0];
    const darr = d.split('-');
    const t = arr[1];
    const tarr = t.split('.000');
    const marr = tarr[0].split(':');
    const dd =
      parseInt(darr[0]) +
      '/' +
      parseInt(darr[1]) +
      '/' +
      parseInt(darr[2]) +
      ' ' +
      parseInt(marr[0]) +
      ':' +
      parseInt(marr[1]) +
      ':' +
      parseInt(marr[2]);
    return this.formatDateTime(dd);
  },
  formatDateTime(date: string) {
    const time = new Date(Date.parse(date));
    time.setTime(time.setHours(time.getHours() + 8));
    const Y = time.getFullYear() + '/';
    const M = this.addZero(time.getMonth() + 1) + '/';
    const D = this.addZero(time.getDate()) + ' ';
    const h = this.addZero(time.getHours()) + ':';
    const m = this.addZero(time.getMinutes()) + ':';
    const s = this.addZero(time.getSeconds());
    return `${Y}${M}${D}${h}${m}${s}`;
  },
  getDateTime() {
    const time = new Date();
    const Y = time.getFullYear() + '-';
    const M = this.addZero(time.getMonth() + 1) + '-';
    const D = this.addZero(time.getDate()) + ' ';
    const h = this.addZero(time.getHours()) + ':';
    const m = this.addZero(time.getMinutes()) + ':';
    const s = this.addZero(time.getSeconds());
    return `${Y}${M}${D}${h}${m}${s}`;
  },
  getDateDay() {
    const time = new Date();
    const Y = time.getFullYear() + '-';
    const M = this.addZero(time.getMonth() + 1) + '-';
    const D = this.addZero(time.getDate()) + ' ';
    return `${Y}${M}${D}`;
  },
  addZero(num: number) {
    return num < 10 ? '0' + num : num;
  },
  // 获取当前时间戳(秒)
  getTimestampS() {
    return Date.parse(new Date().toString()) / 1000;
  },
  // 获取当前时间戳(毫秒)
  getTimestampMS() {
    return new Date().getTime();
  },
  // 获得某月的天数
  getSignMonthDays(Year: number, Month: number) {
    const monthBeginDate = new Date(Year, Month - 1, 1).getTime(); //得到某月开始日期
    const monthEndDate = new Date(Year, Month, 1).getTime(); //得到某月下一个月的开始日期
    const dayNum = (monthEndDate - monthBeginDate) / (1000 * 60 * 60 * 24);
    return dayNum;
  },
  // 获取未来几个月一共的天数
  getSumMonthDays(Year: number, MonthS: number, time: number) {
    let sum = 0;
    let start = MonthS;
    for (let i = 0; i < time; i++) {
      sum += this.getSignMonthDays(Year, start);
      start = start === 12 ? 1 : ++start;
    }
    return sum;
  },
};

// 生成随机数
export const randomNum = (minNum: number, maxNum: number): number => {
  if (maxNum && minNum) {
    return parseInt(String(Math.random() * (maxNum - minNum + 1) + minNum), 10);
  } else if (minNum) {
    return parseInt(String(Math.random() * minNum + 1), 10);
  } else {
    return 0;
  }
};
