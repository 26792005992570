import { defineStore } from 'pinia';
import { AgreementResultData } from '@/api/login';

export const useUserStore = defineStore('user', () => {
  const userCity = ref('');
  function setCity(city: string) {
    userCity.value = city;
  }

  const axiosBaseURL = ref('');
  function setAxiosBaseURL(url: string) {
    axiosBaseURL.value = url;
  }

  const urlMap = ref<any>({});
  function setUrlMap(map: any) {
    urlMap.value = map;
  }
  const bodyCode = ref('');
  function setBodyCode(url: string) {
    bodyCode.value = url;
  }

  const agreementURLArr = ref<AgreementResultData>();
  function setAgreementURL(url: AgreementResultData) {
    agreementURLArr.value = url;
  }

  const wapHomeUrl = ref<string>('');
  function setWapHomeUrl(url: string) {
    wapHomeUrl.value = url;
  }
  return {
    userCity,
    setCity,
    axiosBaseURL,
    setAxiosBaseURL,
    agreementURLArr,
    setAgreementURL,
    wapHomeUrl,
    setWapHomeUrl,
    urlMap,
    setUrlMap,
    bodyCode,
    setBodyCode,
  };
});
