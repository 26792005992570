let SIGNKEY = '';
let ossPath = '';

/*
 * TODO 需要跟换接口时需要修改的地方
 * */
switch (import.meta.env.VITE_USER_NODE_ENV) {
  case 'pro_qh':
    ossPath = 'https://hxqb-pro.oss-cn-chengdu.aliyuncs.com/open/domain/'; // 阿里云正式动态地址  除非跟换阿里云账号其余情况不用更改
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  case 'test':
    ossPath = 'https://hxqb-test.oss-cn-chengdu.aliyuncs.com/test/open/domain/'; // 阿里云测式动态地址  不用更改
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  default:
    ossPath = 'https://hxqb-pro.oss-cn-chengdu.aliyuncs.com/open/domain/'; // 阿里云测式动态地址  不用更改
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
}
export { SIGNKEY, ossPath };
