<template>
  <van-overlay :show="requestLoading" @click.stop class="pageLoading">
    <van-loading size="48px" color="#1989fa" vertical>{{
      requestLoadingText ? requestLoadingText : '数据加载中...'
    }}</van-loading>
  </van-overlay>
  <router-view />
</template>

<script setup lang="ts">
  import copyPaste from '@/utils/lib/copy-paste';
  import { storeToRefs } from 'pinia';
  import { useMyLoading } from '@/store/modules/loading';

  const useMyLoading_ = useMyLoading();
  const { requestLoading, requestLoadingText } = storeToRefs(useMyLoading_);
  onMounted(async () => {
    /**不可粘贴*/
    copyPaste.disable();
  });

  onBeforeUnmount(() => {
    copyPaste.enable();
  });
</script>

<style>
  #app {
    font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  * {
    margin: 0;
    padding: 0;
  }
  .pageLoading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
