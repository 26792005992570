import http, { StrObjStr } from '@/utils/http';
import { AgreementType } from '@/utils/constant';
/**
 * 获取验证码
 * **/
export const getSmsCode = (params: StrObjStr): Promise<StrObjStr> =>
  http.get<StrObjStr>(`/customer/login/sms.e`, params);
/**
 * 验证码登录,注册
 * */
export const codeRegister = (data: StrObjStr): Promise<StrObjStr> =>
  http.post<StrObjStr>(`/customer/login/login.e`, data);

/**根据ip获取当前城市*/
export const ipAddr = (): Promise<StrObjStr> => http.get<StrObjStr>(`/customer/ipAnalysis.e`);

/**获取用户信息**/
export const getUserInfo = () => http.get('/customer/baseUserInfo');

/**域名配置查询**/
export interface AgreementResultData {
  list: AgreementType[];
  urlParam: string;
}
export interface AgreementResult {
  id: number;
  packageType: number;
  url: string;
  name: string;
}
export const userAgreementResult = (data: { packageType: number }): Promise<AgreementResultData> =>
  http.get<AgreementResultData>(`/customer/userAgreementResultNew.e`, data);
